<template>
    <div>
        <top-nav></top-nav>

        <div class="content my-5 pa-4 text-left">
            <h2 class="secondary--text">POLÍTICA DE REEMBOLSO Y/O DEVOLUCIONES</h2>

            <p>Nuestra política reembolsos es aplicable antes de comenzar a utilizar los paquetes de entrenamiento (presencial o en linea). Si utilizó, aunque sea una vez, el paquete de entrenamiento (presencial o en linea), no podemos ofrecerle un reembolso completo o cambio. El reembolso se efectuará siempre y cuando el paquete no haya sido utilizado.</p>

            <h4 class="secondary--text my-3">Artículos adicionales:</h4>

            <p>Vestimenta de ejercicios marca Petu Power <br>Toallas / Medias / Gorras marca Petu Power</p>

            <p>No se aceptará su devolución si el artículo fue utilizado. Si el mismo no fue utilizado, se procesará su reembolso y se aplicará un crédito automáticamente a su tarjeta de crédito o método de pago original, dentro de 5 días a prtir de la fecha de compra.</p>

            <h4 class="secondary--text my-3">Reembolsos tardíos o faltantes:</h4>

            <p>Si no recibe su reembolso, primero revise su cuenta bancaria nuevamente.</p>
            <p>Luego, comuníquese con la compañía de su tarjeta de crédito o instituación bancaria, puede tomar algún tiempo antes de que su reembolso se publique oficialmente. Si no lo ve registrado en un término de 5 días, contáctenos.</p>
            <p>Solo se pueden reembolsar los artículos con precio regular. Los artículos en oferta no se pueden reembolsar.</p>

            <h4 class="secondary--text my-3">Envío y devoluciones:</h4>

            <p>Para devolver su producto comprado en linea o en el gimnasio, debe enviarlo por correo a la dirección a la izquierda mencionada o entregarla personalmente con previa notificación.</p>
        </div>
    </div>
</template>

<script>
    import TopNav from '../components/TopNav.vue'
    import BottomNav from '../components/BottomNav'

    export default {
        components: { TopNav, BottomNav },
    }
</script>

<style lang="scss" scoped>
.content {
    margin-bottom: 70px;
}
.content h2 {
    font-size: 20px;
    font-weight: 500;
    // font-family: 'Roboto';
}
</style>